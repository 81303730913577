let request = new XMLHttpRequest();
let token = '';
let fileName = '';
let fileType = '';
let fileContent = '';
let fileUpload = '';
let tiketNumber = '';
let userName_afterLogin = '';
let userEmail_afterLogin = '';
let product = 'TIMO';
let timoURL = 'https://uat.timo.global';
let productionHostName = 'xbpeurope.com';
let botServiceHealthURL = 'https://chat-pybridge-qa.exela.global/v2/health';

// Vanilla JavaScript equivalent of $(document).ready()
document.addEventListener( 'DOMContentLoaded', function () {
	// console.log( 'executed.' );

	let isProductionEnvironment = window.location
		? window.location.hostname == productionHostName
		: false;
	// let isProductionEnvironment = false;

	if ( isProductionEnvironment ) {
		timoURL = 'https://api.timo.global';
		botServiceHealthURL = 'https://live-support2.exela.global/v2/health';
	}

	// const isBotServiceOK = await checkBotServiceHealth(botServiceHealthURL);

	// if (!isBotServiceOK) return;
	jQuery( '.open-button' ).on( 'click', function () {
		// alert('srcipt started')
		// console.log( 'clicked' );
		let ax_domainName = window.location.hostname;
		let ax_timo_markup_path;
		// console.log( 'ax_domainName: ', ax_domainName );

		if ( ax_domainName === 'localhost' ) {
			ax_timo_markup_path = 'http://localhost:8888/xbp/timo.html';
		} else {
			ax_timo_markup_path = `https://${ ax_domainName }/timo.html`;

			// console.log( 'ax_timo_markup_path: ', ax_timo_markup_path );
		}

		jQuery( '#masterDiv' ).load(
			ax_timo_markup_path,
			async function ( response, status, xhr ) {
				if ( status == 'error' ) {
					let msg = 'Sorry but there was an error: ';
					jQuery( '#error' ).html(
						msg + xhr.status + ' ' + xhr.statusText
					);
				} else {
					// console.log(
					// 	jQuery( '#userName' ).val() +
					// 		'----' +
					// 		jQuery( '#userEmail' ).val()
					// );
					if ( jQuery( '#userName' ).val() != '' ) {
						userName_afterLogin = jQuery( '#userName' ).val();
						// jQuery("#createdBy").attr("disabled", true);
					} else {
						userName_afterLogin = jQuery( '#userName' ).val();
					}
					if ( jQuery( '#userEmail' ).val() != '' ) {
						userEmail_afterLogin = jQuery( '#userEmail' ).val();
						// jQuery("#requestorEmail").attr("disabled", true);
					} else {
						userEmail_afterLogin = jQuery( '#userEmail' ).val();
					}
					//if(userName_afterLogin != ''){
					jQuery( '#createdBy' ).val( userName_afterLogin );
					//jQuery("#requestorEmail").val(userEmail_afterLogin);
					// jQuery("#createdBy").attr('disabled',true);
					//jQuery("#requestorEmail").attr('disabled',true);
					// }
					// if(userEmail_afterLogin != ''){
					//jQuery("#createdBy").val(userName_afterLogin);
					jQuery( '#requestorEmail' ).val( userEmail_afterLogin );
					// jQuery("#createdBy").attr('disabled',true);
					//  jQuery("#requestorEmail").attr('disabled',true);
					// }
					// let modal = document.getElementById("timoSupport");
					// modal.style.display = "block";
					// let isBotServiceOK = await checkBotServiceHealth(botServiceHealthURL);
					document.getElementById( 'overlay' ).style.display =
						'block';

					jQuery( '.open-button' ).hide();
					// if (!isBotServiceOK) {
					//   jQuery("#supportSwitch").click();
					//   jQuery("#botSwitch").hide();
					// }

					return false;
				}
			}
		);

		jQuery( 'body' ).addClass( 'bacscrollStop' );

		return false;
	} );
	return false;
} );

async function checkBotServiceHealth( serviceURL ) {
	try {
		const status = ( await fetch( serviceURL ) ).status;
		// console.log( 'service status', status );
		return status == 200;
	} catch ( err ) {
		return false;
	}
}

function saveContactSupport() {
	product = jQuery( '#productName' ).val();
	token = 'TXbdSOGFXWrwfcgZbIWNrZHnmdOLRZkau9wDLOrZD7hSMojxcgYk4X7+61Qiutk=';
	let data = {
		product: product,
		customer: 'Exela Technologies',
		created_by: document.getElementById( 'createdBy' ).value,
		type: 'Issue',
		priority: '4',
		shortDescription: document.getElementById( 'shortDescription' ).value,
		fullDescription: document.getElementById( 'fullDescription' ).value,
		requestorEmail:
			document.getElementById( 'requestorEmail' ).value == ''
				? 'kaushlesh.singh@exelaonline.com'
				: document.getElementById( 'requestorEmail' ).value,
	};

	//console.log("final data ==>", data);
	let xhrNew = request.open(
		'POST',
		`${ timoURL }/Ticket_Management_Service/Create_Ticket`,
		true
	);
	request.setRequestHeader( 'Authorization', 'Bearer ' + token );
	request.setRequestHeader( 'Content-Type', 'application/json' );

	request.send( JSON.stringify( data ) );

	request.onload = function () {
		if ( request.status != 200 ) {
			//alert(`Error ${request.status}: ${request.statusText}`); // e.g. 404: Not Found
			jQuery( '#timoSupport' ).hide();
			jQuery( '#resultDiv' ).show();
			jQuery( '#errorDiv' ).show();
			jQuery( '#msg' ).html = 'Error while creating ticket';
		} else {
			// show the result
			let result = JSON.parse( request.response );
			tiketNumber = result.callNumber;
			errMsg = result.errorMsg;
			//alert(tiketNumber)
			if ( tiketNumber == null ) {
				jQuery( '#timoSupport' ).hide();
				jQuery( '#resultDiv' ).show();
				jQuery( '#errorDiv' ).show();
				jQuery( '#msg' ).html = errMsg;
				return false;
			}
			if ( result.callNumber != null && fileName != '' ) {
				request.open(
					'POST',
					`${ timoURL }/Ticket_Management_Service/Create_Attachment`,
					true
				);
				let fileUpload = {
					callNumber: result.callNumber,
					fileType: fileType,
					fileName: fileName,
					attachment: fileContent,
				};

				request.setRequestHeader( 'Authorization', 'Bearer ' + token );
				request.setRequestHeader( 'Content-Type', 'application/json' );

				request.send( JSON.stringify( fileUpload ) );
				request.onload = function () {
					if ( request.status != 200 ) {
						jQuery( '#timoSupport' ).hide();
						jQuery( '#resultDiv' ).show();
						jQuery( '#errorDiv' ).show();
						jQuery( '#msg' ).html = 'Error in attaching file';
						// here symbol $ is used for native JavaScript String interpolation. Its not jQuery.
						//alert(`Error file upload ${request.status}: ${request.statusText}`);
					} else {
						if ( tiketNumber == null ) {
							jQuery( '#timoSupport' ).hide();
							jQuery( '#resultDiv' ).show();
							jQuery( '#errorDiv' ).show();
							jQuery( '#msg' ).html = errMsg;
							return false;
						}
						// console.log( 'response', request.response );
						// document.getElementById("timoSupport").style.display = 'none';
						document.getElementById(
							'contactSupportForm'
						).style.display = 'none';
						jQuery( '#botSwitch' ).hide();
						document.getElementById( 'resultDiv' ).style.display =
							'block';
						document.getElementById( 'successDiv' ).style.display =
							'block';
						// console.log( ' =tiketNumber =', tiketNumber );
						document.getElementById( 'refrenceNumber' ).innerHTML =
							'<strong>' + tiketNumber + '</strong>';
					}
				};
			}
			if ( fileName == '' ) {
				// document.getElementById("timoSupport").style.display = 'none';
				document.getElementById( 'contactSupportForm' ).style.display =
					'none';
				jQuery( '#botSwitch' ).hide();
				document.getElementById( 'resultDiv' ).style.display = 'block';
				document.getElementById( 'successDiv' ).style.display = 'block';
				// console.log( ' =tiketNumber =', tiketNumber );
				document.getElementById( 'refrenceNumber' ).innerHTML =
					'<strong>' + tiketNumber + '</strong>';
			} else {
				// console.log( 'I Have not got file' );
			}
		}
	};

	request.onerror = function () {
		document.getElementById( 'timoSupport' ).style.display = 'none';
		document.getElementById( 'errorDiv' ).style.display = 'block';
		document.getElementById( 'msg' ).innerHTML =
			'Something went wrong with connectivity.';
		return false;
	};
}

function resetForm() {
	jQuery( '#timoSupport' ).show();
	jQuery( '#resultDiv' ).hide();
	jQuery( '#errorDiv' ).hide();
	jQuery( '#msg' ).html = '';
	jQuery( '#contactSupportSubmit' ).removeClass( 'disabledBtn' );
	jQuery( 'body' ).removeClass( 'bacscrollStop' );
}

function previewFile() {
	const preview = document.querySelector( 'img' );
	const file = document.querySelector( 'input[type=file]' ).files[ 0 ];
	const reader = new FileReader();

	reader.addEventListener(
		'load',
		function () {
			// convert image file to base64 string
			fileContent = reader.result.split( ',' )[ 1 ];
		},
		false
	);

	if ( file ) {
		reader.readAsDataURL( file );

		fileType = file.type;
		fileName = file.name;
	}
}

// Chat Ai Click Events
function xbpChatAiClickEvents() {
	jQuery( '.js-chat-reset-form' ).on( 'click', function ( event ) {
		// Prevent the default behavior of the button
		event.preventDefault();

		resetForm();
	} );

	jQuery( '.js-chat-cancel-upload' ).on( 'click', function ( event ) {
		// Prevent the default behavior of the button
		event.preventDefault();

		onCancelBtn();
	} );
}

// Invoke JavaScript function
// Vanilla JavaScript equivalent of $(document).ready()
document.addEventListener( 'DOMContentLoaded', function () {
	xbpChatAiClickEvents();
} );

/* function validation(){
   let isValid =[];
    if(document.getElementById('createdBy').value == '' || document.getElementById('createdBy').value == null){
      isValid.push('createdBy')
      
    }
    if(document.getElementById('shortDescription').value == '' || document.getElementById('shortDescription').value == null){
      isValid.push('shortDescription')
      
    }
    if(document.getElementById('shortDescription').value == '' || document.getElementById('shortDescription').value == null){
      isValid.push('shortDescription')
      
    }
    if(isValid.length > 0){
      return false
    }else{
      return true;
    }
//      document.getElementById('createdBy').isValid

} */

// Example starter JavaScript for disabling form submissions if there are invalid fields
( function () {
	'use strict';
	window.addEventListener(
		'submit',
		function () {
			// Fetch all the forms we want to apply custom Bootstrap validation styles to
			let forms = document.getElementsByClassName( 'needs-validation' );
			// Loop over them and prevent submission
			let validation = Array.prototype.filter.call(
				forms,
				function ( form ) {
					form.addEventListener(
						'submit',
						function ( event ) {
							if ( form.checkValidity() === false ) {
								event.preventDefault();
								event.stopPropagation();
							} else {
								//call save
								// console.log( ' success' );
								// setTimeout(function(){ alert("Hello"); }, 3000)
							}
							form.classList.add( 'was-validated' );
							// console.log( 'fail' );
						},
						false
					);
				}
			);
		},
		true
	);
} )();
